import React from "react";

const knowledgeContent = [
    {
        id: 1,
        colClass: "left",
        title: "Knowledge",
        content: [
            " React.js",
            " Redux",
            " HTML",
            " CSS",
            "Javascript",
            "Git"
        ],
    },
    {
        id: 2,
        colClass: "right",
        title: "Interests",
        content: [
            " Custom Web Apps",
            " Mobile Apps",
        ],
    },
];

const KnowledgeInterest = () => {

    return (
        <>
            {knowledgeContent.map((item) => (
                <div className={item.colClass} key={item.id}>
                    <div className="tokyo_section_title">
                        <h3>{item.title}</h3>
                    </div>
                    <div className="tokyo_tm_skill_list">
                        <ul>
                        {item.content.map((val, i) => (
                            <li key={i}>
                            <span>
                                <img
                                width={10}
                                height={10}
                                className="svg"
                                src="/img/svg/rightarrow.svg"
                                alt="arrow"
                                />
                                {val}
                            </span>
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
};

export default KnowledgeInterest;
