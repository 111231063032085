import React from "react";
import Modal from "react-modal";
import Intro from "../../components/AboutContent/Intro";
import KnowledgeInterest from "../../components/AboutContent/KnowledgeInterest";
import PersonalInfo from "../../components/AboutContent/PersonalInfo";
//import Resume from "../../components/AboutContent/Resume";
//import Skills from "../../components/AboutContent/Skills";
import './about.scss'

Modal.setAppElement("#root");

const About = () => {
    return (
        <>
            <div className="container">
                <div className="tokyo_tm_about">
                    <div className="tokyo_tm_title">
                        <div className="title_flex">
                        <div className="left">
                            <h3>About Me</h3>
                        </div>
                        </div>
                    </div>
                    {/* End title */}

                    <Intro />
                    <div className="tokyo_tm_short_info">
                        <PersonalInfo />
                    </div>
                    {/* End personal info */}
                    <div>
                        <div className="tokyo_tm_button" data-position="left">
                            <a href="/img/cv.pdf" download>
                                <span>Download CV</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End .container */}

            {/* <div className="tokyo_tm_progressbox">
                <div className="container">
                    <div className="in">
                        <Skills />
                    </div>
                </div>
            </div> */}
            {/* End tokyo_tm_progressbox */}

            <div className="tokyo_tm_progressbox"> 
            {/* changed classname for responsive colour background */}
                <div className="container">
                    <div className="in">
                        <KnowledgeInterest />
                    </div>
                </div>
            </div>
            {/* End .tokyo_tm_skillbox */}

            {/* <div className="tokyo_tm_resumebox">
                <div className="container">
                    <div className="in">
                        <Resume />
                    </div>
                </div>
            </div> */}
            {/* End tokyo_tm_resumebox */}

            {/* /ABOUT */}
        </>
    );
};

export default About;
