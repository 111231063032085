import React from "react";
//import social from '../../data/social.json'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const SocialShare = [
    {
        iconName: <LinkedInIcon />,
        link: "https://www.linkedin.com/in/alexandra-saavedra/",
    },
    {
        iconName: <GitHubIcon />,
        link: "https://github.com/alexsaav",
    },
    { 
        iconName: <TwitterIcon />, 
        link: "https://twitter.com/alexnsaav" 
    }
];
const Social = () => {
    return (
        <>
            <ul className="social">
                {SocialShare.map((val, i) => (
                    <li key={i}>
                        <a href={val.link} target="_blank" rel="noreferrer">
                            {/* <img
                                className="svg"
                                src={val.iconName}
                                alt="social"
                            ></img> */}
                            {val.iconName}
                        </a>
                    </li>
                ))}
            </ul>
            {/* END SOCIAL */}
        </>
    );
};

export default Social;
