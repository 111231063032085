//import Social from "../../../Social/Social";
import { Link } from "react-router-dom";
import Modal from "react-modal";

const ModalComponent = ({open, toggle, project}) => {
    if(!open) return;

    return (
        <Modal
            isOpen={open}
            onRequestClose={toggle}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
        >
            <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
                <button className="close-modal" onClick={toggle}>
                    <img src="/img/svg/cancel.svg" alt="close icon" />
                </button>
                {/* END CLOSE ICON */}

                <div className="box_inner">
                    <div className="description_wrap scrollable">
                        <div className="image">
                            <img src="/img/thumbs/4-3.jpg" alt="tumb" />
                            <div
                                className="main modal-image"
                                style={{
                                    backgroundImage: project.imgMain,
                                }}
                            >
                            </div>
                        </div>
                        {/* END IMAGE */}

                        <div className="portfolio_main_title">
                            <h3>{project.title}</h3>
                            <span>Details</span>
                        </div>
                        {/* END portfolio_main_title */}

                        <div className="main_details">
                            <div className="textbox">
                                <p>{project.details}</p>
                                <h6>Technologies</h6>
                                <p>{project.technologies}</p>
                            </div>
                            <div className="detailbox">
                                <ul>
                                    {/* <li>
                                        <span className="first">Client</span>
                                        <span>{project.client}</span>
                                    </li> */}
                                    {project.link && 
                                        <li>
                                            <span className="first">Link</span>
                                            <span>
                                                <Link to={project.link} target="_blank">{project.title}</Link>
                                            </span>
                                        </li>
                                    }
                                    <li>
                                        <span className="first">Date</span>
                                        <span>{project.date}</span>
                                    </li>
                                {/*  <li>
                                        <span className="first">Share</span>
                                        <Social />
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        {/* main_details */}

                        <div className="additional_images">
                            <ul className="gallery_zoom">
                                {project.additionalImages.map(image => {
                                    return (
                                        <li>
                                            <div className="list_inner">
                                                <div className="my_image" >
                                                    <div
                                                        className="main modal-image"
                                                        style={{
                                                            backgroundImage: `url(${image.imgPath})`,
                                                        }}
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )})}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* END BOX INNER */}
            </div>
            {/* END MODALBOX NEWS */}
        </Modal>
    );
};

export default ModalComponent;
