import { useState } from "react";
import classNames from "classnames" 
import { Link } from "react-router-dom";
import sidebarData from '../../data/sidebar/sidebar.json'
import { isActiveLink } from "../../utils/linkActiveChecker";
import { scrollTop } from "../../utils/scrollToTop";
import logo from '../../assets/img/logo/logo.svg'
import './sidebar.scss'


const Sidebar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const toggleShowMenu = () => setShowMenu(!showMenu);
    const hideMenu = () => setShowMenu(false);
    
    return (
        <>
            {/* NAVBAR */}
            <div className="header">
                <div className="header-inner">
                    <div className="logo navbar-logo">
                        <Link className="navbar-brand" to="/">
                            <img
                                src={logo}
                                alt="brand"
                            />
                        </Link>
                    </div>
                    {/* End logo */}

                    <div className="my_trigger" onClick={toggleShowMenu}>
                        <div
                            className={
                                showMenu
                                ? "hamburger hamburger--collapse-r is-active"
                                : "hamburger"
                            }
                            >
                            <div className="hamburger-box">
                                <div className="hamburger-inner"></div>
                            </div>
                        </div>
                        {/* End hamburger menu */}
                    </div>
                </div>
            </div>
            {/* Header */}

            {/* SIDEBAR */}
            <div className={classNames("leftpart", showMenu && "active")}>
                <div className="leftpart_inner">
                    <div className="logo sidebar-logo">
                        <Link className="navbar-brand" to="/">
                            <img
                                width={500}
                                src={logo}
                                alt="brand"
                            />
                        </Link>
                    </div>
                    {/* END LOGO */}

                    <div className="menu">
                        <ul>
                        {sidebarData.map((item) => (
                            <li key={item.id} onClick={hideMenu}>
                                <Link
                                    className={`${
                                    isActiveLink(item.routePath)
                                        ? "active "
                                        : ""
                                    }`}
                                    to={item.routePath}
                                >
                                    <img
                                        width={15}
                                        height={15}
                                        className="svg"
                                        src={item.icon}
                                        alt="homerun"
                                    />
                                    <span className="menu_content" onClick={scrollTop}>{item.menuName}</span>
                                </Link>
                            </li>
                        ))}
                        </ul>
                    </div>
                    {/* END MENU */}
                </div>
            </div>
            {/* END LEFT MENU CONTENT */}
        </>
    )
}

export default Sidebar