import React, { useState } from "react";
import ModalComponent from "./Modal/ModalComponent";
import portfolioData from '../../data/portfolio/portfolio.json'



const PortfolioTwo = () => {
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ currentProject, setCurrentProject ] = useState();

    function toggleModal(project) {
        setCurrentProject(project)
        setModalOpen(!modalOpen);
    }

    return (
        <div data-aos="fade-right" data-aos-duration="1200">
            <div className="container">
                <div className="tokyo_tm_news">
                    <div className="tokyo_tm_title">
                        <div className="title_flex">
                            <div className="left">
                                <h3>Portfolio</h3>
                            </div>
                        </div>
                    </div>
                  {/* END TITLE */}
                    <ul>
                        {portfolioData.map((project) => (
                            <li key={project.id}>
                                <div className="list_inner" onClick={() => toggleModal(project)}>
                                    <div className="image">
                                        <div
                                            className="main "
                                            style={{
                                                backgroundImage: `url(${project.imgThumb})`,
                                            }}
                                            ></div>
                                    </div>
                                    {/* END IMAGE */}

                                    <div className="details">
                                        <div className="extra" style={{justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                                            <span>{project.title}</span>
                                            <p className="date">
                                                <span>{project.date}</span>
                                            </p>
                                        </div>
                                        {/* END EXTRA */}

                                        <h3 className="title">{project.subtitle}</h3>
                                        <div className="tokyo_tm_read_more">
                                            <div className="read-more">
                                                <span>Read More</span>
                                            </div>
                                        </div>
                                        {/* END READ MORE BUTTON */}
                                    </div>
                                    {/* END DETAILS */}
                                </div>
                            </li>
                        ))}
                    </ul>


                    {/* START MODAL */}
                        <ModalComponent 
                            open={modalOpen}
                            toggle={toggleModal}
                            project={currentProject}
                        />
                    {/* END MODAL */}

                </div>
            </div>
            {/* End .container */}
        </div>
    );
};

export default PortfolioTwo;


