import React from 'react'
import img from '../../assets/img/about/background-3.jpg'

const introContent = {
    image: "/img/slider/4.jpg",
    name: "Alexandra Saavedra",
    designation: "Frontend Software Developer",
    text: (
            <>
                <p>
                    Motivated and self-driven developer seeking a junior-level role to utilize my skill set in 
                    JavaScript, HTML, CSS and React. With extensive self-study and hands-on experience spanning over a year, 
                    I am eager to apply my knowledge and collaborate with a dynamic team, making meaningful contributions to 
                    the field of web development while continuing to grow as a professional. 
                    </p>
                    <p>
                    My strong background in teamwork from previous positions, combined with my technical skills, 
                    allows me to effectively contribute to collaborative projects and drive success in the industry.
                </p>
            </>
    ),
};
const Intro = () => {
    
    return (
        <>
            <div className="top_author_image">
                <img src={introContent.image} alt="about" />
            </div>
            <div className="about_title">
                <h3>{introContent.name}</h3>
                <span>{introContent.designation}</span>
            </div>
            <div className="about_text">{introContent.text}</div>
        </>
    );
}

export default Intro