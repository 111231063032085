import React from "react";
import Typed from "react-typed";
import Social from "../Social/Social";
import avatar from '../../assets/img/about/avatar-4-bw.png'
import './home.scss'

const Home = () => {
    return (
        <>
            <div className="container">
                <div className="tokyo_tm_home">
                    <div className="home_content">
                        <div className="avatar">
                            <div
                                className="image avatar_img" id="home-avatar"
                            >
                                <img src={avatar} alt="" />
                            </div>
                            {/* END AVATAR IMAGE */}
                        </div>
                        {/* END AVATAR */}
                        <div className="details">
                            <h3 className="name">Alexandra Saavedra</h3>
                            <h4 className="typer">
                                {/* <Typed
                                    strings={[
                                        "Developer",
                                    ]}
                                    showCursor={false}
                                    typeSpeed={200}
                                /> */}
                            </h4>
                            <p className="job">
                                Frontend Software Developer based in London.
                            </p>
                            {/* END JOB */}
                            <Social />
                        </div>
                        {/* END DETAILS */}
                    </div>
                    {/* END HOME CONTENT */}
                </div>
                {/* END HOME */}
            </div>
        </>
    )
}

export default Home