import React, { useEffect, useState } from "react";
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Sidebar from '../../components/Sidebar/Sidebar'
import { FaMoon, FaSun } from "react-icons/fa";


const Layout = () => {
    const [isDarkMode, setIsDarkMode] = useState(true);

    useEffect(() => {
        document.body.classList.toggle("dark", isDarkMode);
        localStorage.setItem("isDarkModeEnabled", isDarkMode);
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };


    return (
        <Box>
            <Sidebar />
            {/* <button className="theme-switcher-label"  onClick={toggleDarkMode}>
                {isDarkMode ? (
                <>
                    <FaSun />
                </>
                ) : (
                <>
                    <FaMoon />
                </>
                )}
            </button> */}
            <div className="rightpart">
                <div className="rightpart_in">
                    <div className="tokyo_tm_section">
                        <div data-aos="fade-right" data-aos-duration="1200">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default Layout