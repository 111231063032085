import React, { useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './containers/Layout/Layout';
import Home from './components/Home/Home';
//import Portfolio from './components/Portfolio/Portfolio';
import PortfolioTwo from './components/Portfolio/PortfolioTwo';
import About from './containers/About/About';
import { Box } from '@mui/material';
import Aos from "aos";
import { ToastContainer } from "react-toastify";
import "aos/dist/aos.css";
import '../src/styles/scss/style.scss'

//import ThemeSwitch from "../components/switch/ThemeSwitch";


function App() {
    useEffect(() => {
        Aos.init({
            duration: 1200,
        });
    }, []);

      
    return (
        <Box className="tokyo_tm_all_wrap">
            <Router>
                <Routes>
                    <Route path='/' element={ <Layout/> } >
                        <Route path='/' element={ <Home /> } />
                        <Route  path='/about' element={ <About /> } />
                        {/* <Route path='/portfolio' element={ <Portfolio /> } /> */}
                        <Route path='/portfolio' element={ <PortfolioTwo /> } />
                    </Route>
                </Routes>
            </Router>
            <ToastContainer />
        </Box>
    );
}

export default App;
